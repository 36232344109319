import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppPlatformHoverSidebarWapper = styled.div`
  max-width: 1920px;
  height: 110vh;
  margin: auto;
  /* background-color: #f5f5f5; */
  scroll-behavior: smooth;
  overflow: hidden;
  .PlatformSideBarHeading {
    margin: 0;
    text-align: center;
    padding: 70px 0px 100px 0px;
  }
  .tab {
    float: left;
    /* border: 1px solid #ccc; */
    /* background-color: #f1f1f1; */
    width: 33vw;
    height: auto;
    padding-left: 50px;

    @media (min-width: 1920px) {
      width: 35vw;
      padding-left: 124px;
    }
    @media (max-width: 440px) {
      /* padding-left: 4px; */
      padding: 0;
    }
  }

  /* Style the buttons inside the tab */
  .tab button {
    margin-bottom: 14px;
    display: block;
    background-color: inherit;
    color: #34a1d5;
    padding: 22px 16px 22px 90px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    transition: 0.5s;
    /* box-shadow: 8px 8px 10px 0 rgb(0 0 0 / 10%); */
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media (max-width: 1090px) {
      font-size: 18px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
      padding-left: 12px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      padding-left: 4px;
      margin: 0;
    }
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    color: #047cc2;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    font-size: 25px;
    border-left: 5px solid #047cc2;
    @media (max-width: 440px) {
      font-size: 13px;
    }
    @media (max-width: 825px) {
      font-size: 13px;
      padding-left: 10px;
    }
  }

  /* Create an active/current "tab button" class */
  .tab button.active {
    color: #047cc2;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);

    border-left: 5px solid #047cc2;
  }

  /* Style the tab content */
  .tabcontent {
    float: left;
    padding: 0px 0px 0px 80px;
    /* border: 1px solid #ccc; */
    width: 60vw;
    border-left: none;
    height: 100vh;
    display: block;
    @media (max-width: 440px) {
      margin: 0;
      padding: 4px;
    }
    @media (min-width: 1920px) {
      width: 43vw;
      padding-left: 124px;
    }
  }

  /* Clear floats after the tab */
  .clearfix::after {
    content: '';
    clear: both;
    display: table;
  }
  .Sidebar-imageOne {
    width: 15vw;
    height: auto;
    display: block;
    margin-left: 160px;
    margin-right: auto;
    @media (max-width: 440px) {
      margin-left: 80px;
    }
    @media (min-width: 2560px) {
      width: 9vw;
      height: auto;
    }
    @media (min-width: 1920px) {
      width: 11vw;
      height: auto;
    }
  }
  .SideBar-points {
    margin: 20px 0px 0px 0px;
    font-size: 18px;
    @media (max-width: 1090px) {
      font-size: 16px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      margin: 4px;
    }
  }
  .Sidebar-icons {
    width: 20px;
    height: auto;
    padding: 0px 5px 0px 0px;
  }
  .RightSidetabcontent {
    width: 100vw;
  }
  .tablinks {
    border-radius: 50px;
  }
`

export const AppPlatformHoverSidebar = () => {
  function openCity(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, cityName: string) {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabcontent') as HTMLCollectionOf<HTMLElement>
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }
    const cityElement = document.getElementById(cityName)
    if (cityElement) {
      cityElement.style.display = 'block'
    }
    evt.currentTarget.className += ' active'
  }

  return (
    <AppPlatformHoverSidebarWapper>
      <ReUseHTwotag
        Heading="Do your work, your way, while enjoying an intuitively designed modern experience"
        HeadingStyle="PlatformSideBarHeading"
      />
      <div className="SideBarContainer">
        <div className="tab">
          <button className="butn tablinks" onMouseOver={(event) => openCity(event, 'AI/MLPoweredPlatforms')}>
            Intake Orchestration
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'TouchlessInvoice')}>
            Boards
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'ReduceRisk')}>
            Workflows
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'AutomatedWay')}>
            Scorecards
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'RapidlyConnect')}>
            Collaboration
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'VisibilityAcross')}>
            Integration Hub
          </button>
        </div>
        <div className="RightSidetabcontent">
          <div id="AI/MLPoweredPlatforms" className="FirstSlide tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Intake-Orchestration-Icon.png"
              className="Sidebar-imageOne"
            />
            <ReUseHTwotag
              Heading="Start with any employee, any time, from any device"
              HeadingStyle="PlatformManagementHeading"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Enable your business stakeholders to request what they need while staying compliant to corporate policies.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Provides global visibility, insight, and control across your entire Spend Management process.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Applicable for all Spend Categories (Direct, Indirect and Services).
            </p>
          </div>

          <div id="TouchlessInvoice" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-Boards.png"
              className="Sidebar-imageOne"
            />
            <ReUseHTwotag
              Heading="Your centralized control tower to do your work, your way"
              HeadingStyle="PlatformManagementHeading"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />A centralized service desk to efficiently
              conduct all Procurement activities.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Enable teams to organize work, share and track progress with real-time visibility to stakeholders, set
              priorities, share ideas, and keep everyone accountable.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Boards can be tailored to meet your specific needs to help coordinate efforts and keep everything simple
              and trackable.
            </p>
          </div>

          <div id="ReduceRisk" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Workflows-Icon.png"
              className="Sidebar-imageOne"
            />
            <ReUseHTwotag
              Heading="Make it easy to enforce compliant processes"
              HeadingStyle="PlatformManagementHeading"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Easily adhere to global Delegation of Authority (DOA) policy requirements.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Dynamically move tasks through a series of steps from initiation to completion with serial and parallel
              path approval routing.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Eliminate bottlenecks that come from needing to check-in with leadership before moving forward with your
              project.
            </p>
          </div>
          <div id="AutomatedWay" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Scorecards-Icon.png"
              className="Sidebar-imageOne"
            />
            <ReUseHTwotag
              Heading="Measure the critical factors for existing and potential suppliers"
              HeadingStyle="PlatformManagementHeading"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Qualification - Rapidly qualify your potential suppliers and share with internal stakeholders for risk and
              control.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Onboarding - Quickly and easily bring Suppliers into your company and build a complete profile.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Evaluation - Share and get consolidated evaluation scores from all internal stakeholders during RFx
              events.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Business Reviews - Assess the health of business relationships with your suppliers through QBR or Annual
              Reviews.
            </p>
          </div>
          <div id="RapidlyConnect" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Collaboration-Icon.png"
              className="Sidebar-imageOne"
            />
            <ReUseHTwotag
              Heading="Stop relying on email to drive discussions"
              HeadingStyle="PlatformManagementHeading"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Quick and instant communication with internal and external stakeholders.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Share Comments, Files and Attachments in Microsoft Teams.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Gain wisdom from historical project records which stay in perpetuity.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Audit Trails across all activities provide transparency and security.
            </p>
          </div>

          <div id="VisibilityAcross" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Integration-Hub-Icon.png"
              className="Sidebar-imageOne"
            />
            <ReUseHTwotag
              Heading="Rapid and efficient sharing of data with ERP’s and popular third-party systems"
              HeadingStyle="PlatformManagementHeading"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Secure and Modern Architecture.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Scalable for Large Data Volumes and File Sizes.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              REST-API and Webhook Connections to combine Third-Party System data into Raindrop.
            </p>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </AppPlatformHoverSidebarWapper>
  )
}
